import { matchPath, useLocation } from 'react-router-dom';

import { useOnboarding } from '@/manager-onboarding';

export function useShouldRedirectToCompanyOnboard() {
  const location = useLocation();
  const { pathname } = location;
  const companyOnboardingPath = '/home/onboarding/company-info';
  const { companyInfo, isLoading } = useOnboarding();
  const shouldRedirectToOnboard =
    !matchPath(companyOnboardingPath, pathname) && !companyInfo && !isLoading;

  return {
    shouldRedirectToOnboard,
    companyOnboardingPath,
  };
}
