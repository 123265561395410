import { isSameDay, startOfDay } from 'date-fns';

import { daysInMonth, getStartDay } from './ui.date-range-picker.helpers';

interface DateRangePickerCalendarProps {
  month: number;
  year: number;
  startDate: Date | null;
  endDate: Date | null;
  onChange: (date: Date) => void;
}

export default function DateRangePickerCalendar({
  month,
  year,
  startDate,
  endDate,
  onChange,
}: DateRangePickerCalendarProps): JSX.Element {
  const headers = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'];

  const totalDaysInAGivenMonth = daysInMonth(month, year);

  let startDayOfWeekInAGivenMonthStartingFromMonday =
    getStartDay(month, year) - 1;

  if (startDayOfWeekInAGivenMonthStartingFromMonday < 0)
    startDayOfWeekInAGivenMonthStartingFromMonday = 6;

  const calendarDaysGrid = Array(6 * 7).fill(null);

  for (let day = 1; day <= totalDaysInAGivenMonth; day++) {
    calendarDaysGrid[startDayOfWeekInAGivenMonthStartingFromMonday + day - 1] =
      day;
  }

  return (
    <div className="flex flex-col gap-1">
      <div className="grid h-fit grid-cols-7 grid-rows-1 gap-2">
        {headers.map((header, index) => (
          <div
            key={index}
            className="text-center text-xs font-normal text-zinc-400"
          >
            {header}
          </div>
        ))}
      </div>
      <div className="grid grid-cols-7 gap-y-1">
        {calendarDaysGrid.map((day) => (
          <div key={`${day}-${month}-${year}`}>
            {day && (
              <button
                data-selected={
                  (startDate &&
                    isSameDay(new Date(year, month, day), startDate)) ||
                  (endDate && isSameDay(new Date(year, month, day), endDate))
                }
                data-range={
                  startDate &&
                  endDate &&
                  new Date(year, month, day) > startDate &&
                  new Date(year, month, day) < startOfDay(endDate)
                }
                className="flex h-11 w-11 items-center justify-center rounded text-sm font-normal text-zinc-800 transition-all hover:border hover:border-stone-300 active:bg-zinc-900 active:text-white data-[range=true]:bg-gray-200 data-[selected=true]:bg-zinc-800 data-[selected=true]:text-white"
                onClick={() => {
                  const newDate = new Date(year, month, day);
                  onChange(newDate);
                }}
              >
                {day}
              </button>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
