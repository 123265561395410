import { GET_INSIGHTS_LIGHT_WEIGHT } from '@/manager-graphql';
import { useQuery } from '@apollo/client';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

export function useSurveyIframeMessages(expandedInitialState: boolean) {
  const [expandIframe, setExpandIframe] = useState(expandedInitialState);
  const [surveyRatingBoxHeight, setSurveyRatingBoxHeight] = useState(0);
  const iframeRef = useRef<HTMLIFrameElement>(null);
  useEffect(() => {
    const handlePostMessage = (event: MessageEvent) => {
      if (event.data.type === 'surveyResponseHeight') {
        setSurveyRatingBoxHeight(event.data.height);
      }
      if (event.data.type === 'action') {
        if (event.data.action === 'hideChat') {
          setExpandIframe(false);
        } else if (event.data.action === 'showChat') {
          setExpandIframe(true);
        }
      }
    };
    window.addEventListener('message', handlePostMessage);
    return () => window.removeEventListener('message', handlePostMessage);
  }, []);

  return { expandIframe, surveyRatingBoxHeight, iframeRef };
}

export function useOnboardingSurvey() {
  const { id: surveyId = '' } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const location = useLocation();
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const questionId = location.state?.questionId ?? null;

  const { data, startPolling, stopPolling } = useQuery(
    GET_INSIGHTS_LIGHT_WEIGHT,
    {
      variables: { filter: { questionId } },
      skip: !questionId || isButtonEnabled,
    }
  );

  useEffect(() => {
    if (questionId && surveyId) {
      startPolling(1000);
    }
    return stopPolling;
  }, [questionId, surveyId, startPolling, stopPolling]);

  useEffect(() => {
    if (data?.listInsights?.items?.length) {
      setIsButtonEnabled(true);
      stopPolling();
    }
  }, [data, stopPolling]);

  const handleButtonClick = useCallback(() => {
    if (isButtonEnabled) {
      navigate('/questions');
    }
  }, [isButtonEnabled, navigate]);

  const getSurveyUrl = useCallback((id: string) => {
    const host = window.location.origin;
    return `${host}/survey/manager-embed/${id}`;
  }, []);

  return {
    surveyId,
    isButtonEnabled,
    handleButtonClick,
    getSurveyUrl,
    questionId,
    survey: location?.state,
  };
}
