import { Skeleton } from '@/shared-ui';
import { useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { ManagerOnboardingLayout } from './manager-onboarding-layout';
import {
  useOnboardingSurvey,
  useSurveyIframeMessages,
} from './manager-onboarding-survey.hooks';
import { useOnboarding } from './manager-onboarding.hooks';

export default function OnboardingSurvey() {
  const navigate = useNavigate();
  const { surveyId, isButtonEnabled, handleButtonClick, getSurveyUrl } =
    useOnboardingSurvey();
  const { companyInfo, isLoading: isCompanyInfoLoading } = useOnboarding();
  const { expandIframe, surveyRatingBoxHeight, iframeRef } =
    useSurveyIframeMessages(isButtonEnabled);

  useEffect(() => {
    if (!surveyId) {
      navigate('/home');
    }
  }, [surveyId, navigate]);

  if (!surveyId) {
    return null; // Render nothing while redirecting
  }

  const shouldRedirectToHome =
    companyInfo?.insightsCount && companyInfo.insightsCount > 0;

  if (shouldRedirectToHome) {
    return <Navigate replace to={'/home'} />;
  }

  if (isCompanyInfoLoading) {
    return (
      <ManagerOnboardingLayout>
        <div className="flex flex-col gap-4 p-5">
          <Skeleton className="h-14 w-full" />
          <Skeleton className="h-32 w-full" />
        </div>
      </ManagerOnboardingLayout>
    );
  }

  return (
    <ManagerOnboardingLayout
      hideHeader
      title={`I'm almost done learning about ${companyInfo?.name}. While I'm wrapping up, please respond to the question below to test how I work to uncover insights.`}
    >
      <div className="flex flex-col space-y-6 pb-6">
        <div className="px-6">
          <iframe
            src={getSurveyUrl(surveyId)}
            title="Survey"
            className="w-full"
            ref={iframeRef}
            style={{
              height: expandIframe ? '50vh' : surveyRatingBoxHeight + 10,
              transition: 'height 0.3s',
            }}
          >
            <p>Your browser does not support iframes.</p>
          </iframe>
        </div>
        <div className="w-full border-gray-200 px-6 pt-6">
          <button
            type="button"
            className={twMerge(
              'inline-flex h-14 w-full items-center justify-center rounded-lg bg-gray-800 px-4 py-2 text-base font-normal leading-normal text-white transition-opacity duration-200 ease-in-out',
              !isButtonEnabled && 'cursor-not-allowed opacity-50'
            )}
            disabled={!isButtonEnabled}
            onClick={handleButtonClick}
          >
            Done testing, let me in
          </button>
        </div>
      </div>
    </ManagerOnboardingLayout>
  );
}
