import { Navigate, useOutlet } from 'react-router-dom';
import { useShouldRedirectToCompanyOnboard } from './home-wrapper.hooks';

export default function HomeWrapper() {
  const outlet = useOutlet();
  const { shouldRedirectToOnboard, companyOnboardingPath } =
    useShouldRedirectToCompanyOnboard();

  if (shouldRedirectToOnboard) {
    return <Navigate replace to={companyOnboardingPath} />;
  }

  return outlet;
}
